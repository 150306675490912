.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
}

.col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.wrap {
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.justify-start {
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}

.justify-center {
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.justify-end {
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

.justify-around {
	-ms-flex-pack: distribute;
	    justify-content: space-around;
}

.justify-between {
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}

.align-start {
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
}

.align-center {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.align-end {
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
}

.align-around {
	-ms-flex-line-pack: distribute;
	    align-content: space-around;
}

.align-between {
	-ms-flex-line-pack: justify;
	    align-content: space-between;
}

.self-start {
	-ms-flex-item-align: start;
	    align-self: flex-start;
}

.self-center {
	-ms-flex-item-align: center;
	    -ms-grid-row-align: center;
	    align-self: center;
}

.self-end {
	-ms-flex-item-align: end;
	    align-self: flex-end;
}

.flex-grow {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0;
	        flex: 1 0;
}

.flex-shrink {
	-webkit-box-flex: 0;
	    -ms-flex: 0 1;
	        flex: 0 1;
}