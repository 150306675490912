@import '../core/styles/globals.scss';

.dashboard-layout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	h1.title {
		font-size: 26px;
		font-weight: 500;
	}

	.link-btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
			-ms-flex-align: center;
				align-items: center;
		color: #2a354f;

		&:hover {
			& > .label {
				text-decoration: underline;
			}
		}
		
		.label {
			margin-right: 5px;
		}

		.icon::before {
			-webkit-transform: rotate(-90deg);
			    -ms-transform: rotate(-90deg);
			        transform: rotate(-90deg);
		}
	}

    .content {
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;

        .lead-progress {
			margin-bottom: 30px;

            .progress-header {
				margin-bottom: 15px;

                .header-info {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
					-webkit-box-align: center;
						-ms-flex-align: center;
							align-items: center;
                    -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
							justify-content: space-between;

                    .info {
						color: #2a354f;
						font-weight: 500;
                    }
                }
            }

            .progress-container {
				margin-bottom: 15px;
            }

            .progress-actions {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
				-webkit-box-pack: end;
				    -ms-flex-pack: end;
				        justify-content: flex-end;
            }
		}

		.info-title {
			text-transform: uppercase;
			color: #8DABC4;
			font-size: 15px;
			margin-bottom: 8px;
			font-weight: 500;
		}

		.info-label {
			display: flex;
			align-items: center;
			font-size: 22px;
			font-weight: 500;

			.loader {
				margin-left: 12px;
			}
		}

		.roi-widget {
			margin-bottom: 30px;

			.roi-calculation {
				margin-right: 8px;
				font-size: 22px;
				font-weight: 500;
			}

			.roi-content {
				display: flex;
				flex-wrap: wrap;
				padding: 0 20px 20px;
			}

			.roi-header {
				margin-bottom: 15px;
			}

			.roi-summary {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				padding: 20px 20px 0 20px;

				.loader {
					margin-left: 12px;
				}
			}

			.roi-items {
				width: 50%;
			}
			.roi-item, .roi-input {
				margin: 10px 0 20px;
			}

			.roi-textinput {
				width: 60px;
				border: 0px;
				border-bottom: 2px solid $green;

				font-family: inherit;
				font-size: 20px;
				font-weight: 500;

				color: $green;

				&.is-currency {
					width: 120px;
				}
			}

			.roi-input-symbol {
				color: $green;
			}

			.roi-calculate {
				margin-top: 30px;
			}
		}

		.stages-widget {
			margin-bottom: 30px;

			.stages-header {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-bottom: 15px;

				.link-btn {
					margin-left: 16px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					    -ms-flex-align: center;
					        align-items: center;
				}
			}

			.stages-list {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-wrap: wrap;
				    flex-wrap: wrap;

				.stage-item {
					min-width: 150px;
					margin-right: 15px;
					margin-bottom: 15px;
					width: 20%;
					padding: 20px;

					.info {
						.link-btn {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							    -ms-flex-align: center;
							        align-items: center;
						}
					}
				}
			}
		}

        .setup-progress {
			margin-bottom: 30px;

			.section-header {
				margin-bottom: 10px;
			}

			.section-content {
				.setup-header {
					padding: 15px 20px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: justify;
					    -ms-flex-pack: justify;
					        justify-content: space-between;
					-webkit-box-align: center;
					    -ms-flex-align: center;
					        align-items: center;

					.progress-stats {
						width: 250px;

						.stats-title {
							color: #2a354f;
							font-weight: 500;
							margin-bottom: 5px;
							font-size: 16px;
						}

						.progress-wrapper {
							margin-bottom: 5px;
						}

						.stats-value {
							color: #2a354f;
							font-weight: 500;
							font-size: 14px;
						}
					}

					.plan-info {
						.label {
							margin-right: 8px;
							color: #2a354f;
							font-size: 15px;
						}

						.pill {
							color: #EE6C4D;
							border-color: #EE6C4D;
						}
					}
				}

				.setup-list {
					.setup-item {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						    -ms-flex-align: center;
						        align-items: center;
						padding: 20px;
						border-bottom: 1px solid #C5DBEA;
						cursor: pointer;

						&:first-of-type {
							border-top: 1px solid #C5DBEA;
						}

						&:last-of-type {
							border: none;
						}

						&:hover {
							background-color: #ecf5fd;
						}

						&.completed {
							& > .checkbox-wrapper > .icon,
							& > .info-wrapper > .info,
							& > .action-wrapper > .icon {
								fill: #2a354f;
								color: #2a354f;
							}
						}

						.checkbox-wrapper {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-pack: center;
							    -ms-flex-pack: center;
							        justify-content: center;
							-webkit-box-align: center;
							    -ms-flex-align: center;
							        align-items: center;
							height: 30px;
							width: 30px;
							position: relative;
							z-index: 1;
							margin-right: 4px;

							.icon {
								font-size: 18px;
								fill: #ccc;
							}
						}

						.info-wrapper {
							-webkit-box-flex: 1;
							    -ms-flex-positive: 1;
							        flex-grow: 1;

							.info {
								font-weight: 500;
							}
						}

						.action-wrapper {
							.icon {
								color: #2a354f;

								&::before {
									-webkit-transform: rotate(-90deg);
									    -ms-transform: rotate(-90deg);
									        transform: rotate(-90deg);
								}
							}
						}
					}
				}
			}
		}
    }

    .side-content {
		width: 400px;
		padding: 20px 30px;

        .support-widget {
			.support-header {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: justify;
				    -ms-flex-pack: justify;
				        justify-content: space-between;
				margin-bottom: 15px;
			}

			.support-card {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				    -ms-flex-direction: column;
				        flex-direction: column;
				-ms-flex-pack: distribute;
				    justify-content: space-around;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				height: 300px;
				padding: 20px 40px;

				.info-wrapper {
					text-align: center;

					.title {
						margin-bottom: 10px;
						color: #2a354f;
					}

					.info {
						color: #8DABC4;
					}
				}

				.link-wrapper {
					.support-link {
						color: #2a354f;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
        }
    }
}

.layout-spinner-wrapper {
	margin-top: 40px;
}