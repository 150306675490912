@import './styles/globals.scss';
@import './styles/flex.scss';
@import './styles/native.scss';
@import './styles/notifications.css';

#root, .page-layout, .main-layout {
	height: 100%;
}

* {
	outline: none;
}

.page-layout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.dialer-layout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	width: 100vw;

	.logo-wrapper {
		display: flex;
		-webkit-box-align: center;
			-ms-flex-align: center;
				align-items: center;
		-webkit-box-pack: center;
			-ms-flex-pack: center;
				justify-content: center;
		height: 60px;
		background-color: darken(#2a354f, 5%);

		.logo-sm {
			height: 28px;
			padding: 14px;
		}
	}
}

.dialer-main-layout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	overflow: hidden;
}

.main-layout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	overflow: hidden;
}

.mobile-overlay {
	display: none;

	@media only screen and (max-width: $mobile-break) {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		padding: 40px 20px;
		width: 100%;
	}

	.img-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		margin-bottom: 40px;

		img {
			height: 75px;
			box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
			border-radius: 20px;
		}
	}

	.overlay-header {
		text-align: center;
		margin-bottom: 60px;

		.title {
			margin-bottom: 20px;
			color: #3C4A54;
		}

		.subtitle {
			padding: 0 20px;
			color: #7A8F9D;
		}
	}

	.overlay-content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;

		.btn {
			text-transform: uppercase;
    		line-height: 2.6;
		}
	}

}

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
	min-height: 60px;
    background-color: #2a354f;
	color: #fff;
	
	.page-title {
    	display: -webkit-box;
    	display: -ms-flexbox;
    	display: flex;
    	-webkit-box-align: center;
    	    -ms-flex-align: center;
    	        align-items: center;
    	-webkit-box-pack: center;
    	    -ms-flex-pack: center;
    	        justify-content: center;
		width: 190px;
    	height: 100%;
    	border-right: 1px solid rgba(256,256,256,0.2);
    	font-size: 18px;
	}

	.search-bar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding: 0 20px;
		max-width: 615px;
		margin-right: auto;
		-webkit-transition: 0.15s ease-out all;
		-o-transition: 0.15s ease-out all;
		transition: 0.15s ease-out all;

		&.active {
			background-color: white;
			margin-left: 20px;
			border-radius: 4px;
			color: #2a354f;
			padding: 5px 10px;
			-webkit-box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
			        box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
			border: 1px solid #eee;

			& > .icon {
				fill: initial;
			}

			& > .icon.search-icon {
				margin-right: 10px;

			}

			& > .icon.cancel-icon {
				margin-right: 0;
				cursor: pointer;
			}

			input {
				color: #444;

				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					color: initial;
				}
				&::-moz-placeholder { /* Firefox 19+ */
					color: initial;
				}
				&:-ms-input-placeholder { /* IE 10+ */
					color: initial;
				}
				&:-moz-placeholder { /* Firefox 18- */
					color: initial;
				}
			}
		}

		.icon {
			margin-right: 20px;
			fill: #fff;
			font-size: 16px;
		}

		input {
			background: transparent;
    		outline: none;
    		width: 100%;
    		border: none;
    		color: #fff;
			font-size: 16px;

			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: #fff;
				opacity: 0.5;
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color: #fff;
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color: #fff;
				opacity: 0.5;
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: #fff;
			}
		}

		.search-dropdown > .dropdown-content {
			right: initial;
			width: 600px;
		}

		.customer-item {
			.customer-title {
				margin-right: 10px;
				color: #555;
			}

			.tooltip {
				.icon {
					fill: #888;
					font-size: 16px;

					&.assigned {
						fill: #1ABC9C;
					}
				}
			}

			.source-list {
				.source-item {
					margin-right: 5px;

					&:last-of-type {
						margin-right: 0;
					}

					.source-icon {
						height: 18px;
					}
				}
			}
		}
	}

	.profile-dropdown {
		margin-right: 20px;

		.dropdown-content {
			top: 42px;
			right: 35px;
			width: 180px;

			.options-list {			
				.sign-out-option {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: center;
					    -ms-flex-pack: center;
					        justify-content: center;
					padding-bottom: 10px;

					&:hover {
						background-color: transparent;
					}

					button {
						font-size: 14px;
					}
				}
			}
		}

		.user-info {
			margin-right: 10px;
			text-align: right;

			.user-name {
				font-size: 16px;
				margin-right: 5px;
				max-width: 250px;
				overflow: hidden;
				-o-text-overflow: ellipsis;
				   text-overflow: ellipsis;
				word-break: unset;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				/*! autoprefixer: ignore next */
				-webkit-box-orient: vertical;
			}

			.icon {
				font-size: 16px;
				margin-bottom: 2px;
			}

			.user-agency {
				margin-right: 18px;
				font-size: 14px;
				opacity: 0.7;
				max-width: 250px;
				overflow: hidden;
				-o-text-overflow: ellipsis;
				   text-overflow: ellipsis;
				word-break: unset;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				/*! autoprefixer: ignore next */
				-webkit-box-orient: vertical;
			}
		}

		.avatar-img {
			border-radius: 50%;
			border: 2px solid #ecf5fd;
		}
	}
}

.layout {
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	overflow: auto;

	.content {
		padding: 20px 30px;

		.content-header {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin-bottom: 20px;

			.header-info {
				-webkit-box-flex: 1;
				    -ms-flex-positive: 1;
				        flex-grow: 1;
			}

			.header-actions {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				    -ms-flex-direction: column;
				        flex-direction: column;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
			}
		}
	}
}

.sub-dashboard {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	background-color: #fff;
	overflow-y: auto;

	.sub-nav {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		width: 150px;
		min-width: 150px;
		padding: 30px 20px;
		border-right: 1px solid #eee;

		.sub-nav-link {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
				-ms-flex-align: center;
					align-items: center;
			padding: 5px;
			border-radius: 4px;
			color: #555;
			margin-bottom: 10px;
			font-size: 15px;

			&:hover {
				background-color: #ECF5FD;
			}

			&:last-of-type {
				margin-bottom: 10px;
			}

			&.active {
				background-color: #ECF5FD;
				color: darken(#2a354f, 5%);

				& > .icon-wrapper > .icon {
					fill: #2a354f;
				}
			}

			.icon-wrapper {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
					-ms-flex-align: center;
						align-items: center;
				margin-right: 8px;
				color: #aaa;

				.icon {
					font-size: 16px;
					fill: #888;
				}
			}
		}
	}

	.sub-main {
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 30px;

		&.tint {
			background-color: #F0F4F8;
		}

		.sub-view {
			header {
				color: #2C405A;

				h1 {
					font-weight: normal;
					margin-bottom: 20px;
				}

				h4 {
					font-size: 16px;
					margin-top: -10px;
					margin-bottom: 20px;
				}
			}
		}
	}
}

.card-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	list-style: none;

	& > li {
		margin: 0 20px 20px 0;

		&:last-of-type {
			margin: 0;
		}
	}
}

.card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	margin-bottom: 10px; 

	.card-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		height: 55px;
		padding: 0 15px;
		border-bottom: 1px solid #eee;
	}

	.card-body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
		padding: 20px;
	}

	.card-footer {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		padding: 15px;
		border-top: 1px solid #eee;
	}
}

.tooltip {
    $transTime: 100ms;
	position: relative;

    .tooltip-label {
		position: absolute;
		z-index: 999;
		background-color: #2a354f;
		color: #eee;
		width: 90px;
		min-width: 3em;
		font-size: 14px;
		padding: 5px 20px;
		text-align: center;
		top: -50%;
		left: -50%;
		border-radius: 4px;
		-webkit-box-shadow: 0 1em 2em -0.5em rgba(0,0,0,.35);
		        box-shadow: 0 1em 2em -0.5em rgba(0,0,0,.35);

        &:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border: 6px solid transparent;
			border-right-color: #2a354f;
			z-index: -1;
			-webkit-transform: translateX(-50%);
			    -ms-transform: translateX(-50%);
			        transform: translateX(-50%);
			-webkit-transition: right 100ms ease;
			-o-transition: right 100ms ease;
			transition: right 100ms ease;
        }
	}

    &.visible {
        .tooltip-label {
            -webkit-transform: translateX(30%);
                -ms-transform: translateX(30%);
                    transform: translateX(30%);
            opacity: 1;
            visibility: visible;
            -webkit-transition: opacity $transTime, 
                        visibility $transTime 0s, 
                        -webkit-transform $transTime ease;
            transition: opacity $transTime, 
                        visibility $transTime 0s, 
                        -webkit-transform $transTime ease;
            -o-transition: transform $transTime ease, 
                        opacity $transTime, 
                        visibility $transTime 0s;
            transition: transform $transTime ease, 
                        opacity $transTime, 
                        visibility $transTime 0s;
            transition: transform $transTime ease, 
                        opacity $transTime, 
                        visibility $transTime 0s, 
                        -webkit-transform $transTime ease;
            &:after {
				top: 35%;
				left: -6px;
			}
			
			&.bottom {
				-webkit-transform: translateY(30%) translateX(0);
				    -ms-transform: translateY(30%) translateX(0);
				        transform: translateY(30%) translateX(0);

				&:after {
					top: -11px;
					left: 50%;
					border-right-color: transparent;
					border-bottom-color: #2a354f;
				}
			}
        }
	}

    &.hidden {
        .tooltip-label {
            -webkit-transform: translateX(40%);
                -ms-transform: translateX(40%);
                    transform: translateX(40%);
            opacity: 0;
            visibility: hidden;
            -webkit-transition: opacity $transTime, 
                        visibility $transTime $transTime, 
                        -webkit-transform $transTime ease;
            transition: opacity $transTime, 
                        visibility $transTime $transTime, 
                        -webkit-transform $transTime ease;
            -o-transition: transform $transTime ease, 
                        opacity $transTime, 
                        visibility $transTime $transTime;
            transition: transform $transTime ease, 
                        opacity $transTime, 
                        visibility $transTime $transTime;
            transition: transform $transTime ease, 
                        opacity $transTime, 
                        visibility $transTime $transTime, 
                        -webkit-transform $transTime ease;
            &:after {
                top: 0;
			}
			
			&.bottom {
				-webkit-transform: translateY(40%) translateX(0);
				    -ms-transform: translateY(40%) translateX(0);
				        transform: translateY(40%) translateX(0);
			}
        }
    }
}

@-webkit-keyframes fade {
    0% {
        top: 0;
        opacity: 1;
    }
    100% {
        top: -1em;
        opacity: 0;
    }
}

@keyframes fade {
    0% {
        top: 0;
        opacity: 1;
    }
    100% {
        top: -1em;
        opacity: 0;
    }
}

.loading-page {
	z-index: 10;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #ecf5fd;

	& > div {
		-ms-flex-item-align: center;
		    -ms-grid-row-align: center;
		    align-self: center;
		text-align: center;

		.icon-lg-wrapper {
			height: 100px;
			width: 100px;
			margin-bottom: 20px;

			img {
				opacity: 0.8;
				box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
				border-radius: 20px;
			}
		}

		.loading-text {
			font-size: 24px;
			letter-spacing: 0.03em;
			color: grey;
		}
	}
}

/*** CUSTOM ELEMENTS ***/
.btn {
	display: inline-block;
	outline: none;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
				0 3px 6px 0 rgba(0, 0, 0, 0.08);
	        box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
				0 3px 6px 0 rgba(0, 0, 0, 0.08);
    color: #fff;
    line-height: 2.4;
    padding: 0 20px;
	font-size: 16px;
	background-color: #ddd;

	&.submit-btn {
		min-height: 40px;
		min-width: 100px;

		& > .loader > .loader-spinner {
			border-left-color: #fff;
		}
	}

	&.primary-btn {
		background-color: #2D9EE0;

		&:hover {
			background-color: darken(#2D9EE0, 3%);
		}

		&.aux-btn {
			background-color: #fff;
			color: #2D9EE0;
			border: 1px solid #2D9EE0;
			-webkit-box-shadow: none;
			        box-shadow: none;
		}
	}

	&.secondary-btn {
		background-color: #fafbfc;
    	padding: 8px 20px;
		-webkit-box-shadow: 0 2px 4px 0 rgba(49, 49, 93, 0.1),
					0 1px 2px 0 rgba(0, 0, 0, 0.08);
		        box-shadow: 0 2px 4px 0 rgba(49, 49, 93, 0.1),
					0 1px 2px 0 rgba(0, 0, 0, 0.08);

		&:hover {
    		background-color: #f4f7f9;
		}

		span, a {
			font-weight: 500;
			color: #2a354f;

			&.icon {
				color: #2a354f;
			}
		}
	}

	&.success-btn {
		background-color: #2a354f;

		&:hover {
			background-color: darken(#2a354f, 3%);
		}
		
		&.aux-btn {
			background-color: #fff;
			color: #2a354f;
			border: 1px solid #2D9EE0;
			-webkit-box-shadow: none;
			        box-shadow: none;
		}
	}

	&.warning-btn {
		background-color: #FACA00;
		
		&:hover {
			background-color: darken(#FACA00, 3%);
		}

		&.aux-btn {
			background-color: #fff;
			color: #FACA00;
			border: 1px solid #FACA00;
			-webkit-box-shadow: none;
			        box-shadow: none;
		}
	}

	&.danger-btn {
		background-color: #f44336;
		
		&:hover {
			background-color: darken(#f44336, 3%);
		}

		&.aux-btn {
			background-color: #fff;
			color: #f44336;
			border: 1px solid #f44336;
			-webkit-box-shadow: none;
			        box-shadow: none;
		}	
	}

	&.default-btn {
		background-color: #888;

		&:hover {
			background-color: darken(#888, 3%);
		}

		&.aux-btn {
			background-color: #fff;
			color: #888;
			border: 1px solid #888;
			-webkit-box-shadow: none;
			        box-shadow: none;
		}
	}

	&.close-btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding: 6px;
		border: none;
		-webkit-box-shadow: none;
		        box-shadow: none;
		background-color: #fff;
		color: #888;

		&:hover {
			background-color: #eee;
		}
	}

	&.disabled-btn {
		background-color: #bbb;
		cursor: auto;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	&.disabled {
		cursor: initial;
	}

	&.fill {
		width: 100%;
		text-align: center;
	}

	&.sm {
		font-size: 14px;
		padding: 0 10px;
	}
}

.dropdown {
	position: relative;
	outline: none;

	.dropdown-content {
		position: absolute;
		right: 0;
		z-index: 99;
		min-width: 160px;
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
		        box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
		border-radius: 4px;
		margin-top: 10px;

		&.lg {
			margin-right: -20px;
			min-width: 340px;
		}

		.options-group {
			margin-bottom: 10px;

			&:first-of-type {
				margin-top: 5px;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			.group-title {
				font-size: 13px;
				line-height: 2.5;
				text-transform: uppercase;
				padding-left: 10px;
				color: #888;
			}

			&:last-of-type {
				option:last-of-type {
					border-bottom: none;
				}
			}
		}

		.options-list {
			list-style: none;
			padding: 0;
			margin: 0;
			border-radius: 6px;
		}

		.option {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			height: 50px;
			padding: 0px 20px;
			border-bottom: 1px solid #eee;
			cursor: pointer;

			& > a,
			& > span {
				color: #555;
			}

			&.options-header {
				background-color: #f7f7f7;
				border-radius: 5px 5px 0 0;
				font-weight: bold;
			}

			&.option-empty:hover {
				background-color: transparent;
			}

			&.selected {
				font-weight: bold;
				color: #2a354f;
				background-color: #ECF5FD;
				cursor: initial;
			}

			&:hover {
				background-color: #ECF5FD;
			}

			&.null {
				&:hover {
					background-color: white;
				}
			}

			&:last-of-type {
				border-radius: 0 0 5px 5px;
			}
		}
	}
}

.dropdown.select {
	min-width: 175px;

	.current-value {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		border: 2px solid #2a354f;
		background-color: #fff;
		padding: 8px 10px;
		border-radius: 4px;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		font-size: 16px;
		cursor: pointer;

		p {
			font-size: 16px;
			line-height: 22px;
			max-width: 200px;
			overflow: hidden;
			-o-text-overflow: ellipsis;
			   text-overflow: ellipsis;
			word-break: unset;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			/*! autoprefixer: ignore next */
			-webkit-box-orient: vertical;
		}

		.icon {
			margin-left: 10px;
			font-size: 20px;
		}
	}

	.dropdown-content {
		width: 100%;
		margin-top: 0;

		.options-list {
			max-height: 200px;
			overflow-y: auto;

			.option-label {
				font-size: 16px;
				line-height: 22px;
				max-width: 200px;
				overflow: hidden;
				-o-text-overflow: ellipsis;
				   text-overflow: ellipsis;
				word-break: unset;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				/*! autoprefixer: ignore next */
				-webkit-box-orient: vertical;
			}
		}
	}
}

.dropdown.dropdown-tag {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 140px;

	.dropdown-content {
		font-size: 14px;
		top: 25px;
		min-width: auto;
		padding: 8px 10px;
		cursor: pointer;
	
		&:hover {
			background-color: #f1f1f1;
		}
	}
}

.tag {
	display: flex;
	align-items: center;
	background-color: transparent;
    padding: 3px 12px;
	border-radius: 15px;
	margin-right: 10px;
	border: 1px solid #555;
	color: #555;
	font-size: 13px;
	line-height: 2;

	&:last-of-type {
		margin-right: 0;
	}
	
	&.danger-tag {
		color: darken($red, 7%);
		border-color: $red;

		&.filled-tag {
			background-color: lighten($red, 23%);
		}

		.icon {
			fill: darken($red, 7%);
			color: darken($red, 7%);
		}

	}
	
	&.success-tag {
		color: darken($green, 10%);
		border-color: $green;

		&.filled-tag {
			background-color: lighten($green, 35%);
		}
		
		.icon {
			fill: darken($green, 5%);
			color: darken($green, 5%);
		}
	}
	
	&.primary-tag {
		color: darken($blue, 7%);
		border-color: $blue;

		&.filled-tag {
			background-color: lighten($blue, 15%);
		}

		.icon {
			fill: darken($blue, 7%);
			color: darken($blue, 7%);
		}
	}

	&.add {
		cursor: pointer;
		margin-bottom: 5px;

		.icon {
			fill: $green;
			color: $green;
		}

		&:hover {
			.icon {
				fill: darken($green, 5%);
				color: darken($green, 5%);
			}
		}
	}

	.icon {
		margin-left: 5px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.4);
		cursor: pointer;

		&.delete {
			&:hover {
				fill: $darkRed;
				color: $darkRed;
			}
		}

		&.add {
			&:before {
				-ms-transform: rotate(45deg); /* IE 9 */
				-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
				transform: rotate(45deg);
			}
		}
	}

	&.white-tag {
		color: #fff;
		border-color: #fff;

		.icon {
			fill: #fff;
			color: #fff;

			&:hover {
				fill: #eee;
				color: #eee;
			}
		}
	}
}

.toggle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;

	&.active {
		.label {
			color: #2a354f;
		}

		.toggle-well {
			background-color: #4ECE3D;

			.toggle-handle {
				margin-left: 22px;
			}
		}
	}

	.label {
		color: #bbb;
		font-weight: bold;
		margin-right: 10px;
	}

	.toggle-well {
		width: 46px;
		height: 27px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 0 3px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		background-color: #ddd;
		border-radius: 4px;

		.toggle-handle {
			height: 21px;
			width: 23px;
			background-color: #fff;
			border-radius: 4px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			    -ms-flex-pack: center;
			        justify-content: center;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			-webkit-box-shadow: 0 3px 2px 0 rgba(9,11,15,0.06);
			        box-shadow: 0 3px 2px 0 rgba(9,11,15,0.06);
			-webkit-transition: 0.2s ease margin-left;
			-o-transition: 0.2s ease margin-left;
			transition: 0.2s ease margin-left;

			.handle-groove {
				height: 10px;
				width: 2px;
				background-color: #eee;
				margin-right: 3px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}
}

.pill {
	border: 1px solid #888;
    color: #888;
    font-size: 14px;
    border-radius: 14px;
    line-height: 1.9;
	padding: 0 8px;
	
	&.success-pill {
		border-color: #81C926;
		color: #81C926;
	}

	&.primary-pill {
		border-color: #2D9EE0;
		color: #2D9EE0;
	}
}

.avatar-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 17px;
    border: 3px solid #fff;
    height: 28px;
	width: 28px;
	font-size: 16px;

	& > svg {
		fill: #fff;
	}
}

/*** LOADING ASSETS ***/
.loader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	height: 100%;

	.loader-spinner {
		border: 5px solid rgba(0, 0, 0, 0.1);
		border-left: 5px solid #0B5FFF;
		-webkit-transform: translateZ(0);
		        transform: translateZ(0);
		-webkit-animation: spinner 1.1s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
		        animation: spinner 1.1s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
		border-radius: 50%;
		width: 50px;
		height: 50px;

		&.spinner-xs {
			width: 18px;
			height: 18px;
			border-width: 3px;
		}

		&.spinner-sm {
			width: 25px;
			height: 25px;
			border-width: 4px;
		}

		&.spinner-md {
			width: 30px;
			height: 30px;
		}
	}
}

@-webkit-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}

.placeholder-group {
	&.lg > .placeholder {
		margin-bottom: 20px;

		&:first-of-type {
			margin-top: 20px;
		}
	}

	&.dark {
		.placeholder {
			background: grey;
		}
	}
}

.placeholder {
	background: lightgrey;
	height: 8px;
	opacity: 0.4;
	border-radius: 6px;
	margin-bottom: 10px;
	
	&.lg {
		width: 80%;
	}

	&.md {
		width: 60%;
	}

	&.sm {
		width: 40%;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

/*** TABLE ***/
.table {
	border-radius: 4px;
	-webkit-box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
				0 3px 6px 0 rgba(0,0,0,0.08);
	        box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
				0 3px 6px 0 rgba(0,0,0,0.08);
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	margin-bottom: 50px;
	background-color: #fff;

	.table-row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: distribute;
		    justify-content: space-around;
		height: 45px;

		& > span {
			-webkit-box-flex: 1;
			    -ms-flex: 1 0 100px;
			        flex: 1 0 100px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			font-size: 14px;

			&:first-of-type {
				padding-left: 20px;
			}
		}
	}

	.table-header {
		input {
			margin: 0 8px 0 0;
		}

		.radio-tooltip {
			text-transform: none;
			font-size: 14px;
			background-color: #4A3A63;
		}

		.table-row {
			border-bottom: 1px solid #D8E5EE;

			span {
				color: #8DABC4;
				text-transform: uppercase;
				letter-spacing: 1px;

				&:last-of-type {
					border-radius: 0 3px 0 0;
				}
			}
		}
	}

	.table-body {
		.table-row {
			border-bottom: 1px solid #E5EEF5;

			& > span {
				color: #2a354f;
				overflow-x: hidden;
				padding-right: 10px;

				&.highlight-cell {
					color: darken(#2a354f, 5%);
				}

				p, a {
					overflow: hidden;
					white-space: nowrap;
					-o-text-overflow: ellipsis;
					   text-overflow: ellipsis;
				}

				.cell-placeholder {
					color: #888;
				}

				&:last-of-type {
					margin: 0;
				}
			}

			&:last-of-type {
				border-bottom: none;
				& > span {
					&.selected {
						margin-bottom: 1px;

						&:last-of-type {
							border-radius: 0 0 3px 0;
						}
					}
				}
			}

			&:hover {
				background-color: #ECF5FD;
			}

			&.null:hover {
				background-color: #fff;
			}
		}
	}
}

/*** INPUT ASSETS ***/
.input-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	margin-right: 20px;

	&:last-of-type {
		margin-right: 0;
	}
}

.input-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
	margin-bottom: 20px;

	&:last-of-type {
		margin-bottom: 0;
	}

	.label {
		margin-bottom: 10px;
		font-size: 14px;
	}

	.input {
		border: none;
		border-bottom: 2px solid #ddd;
		font-size: 16px;
		padding-bottom: 5px;

		&.box-input {
			border: 2px solid #ddd;
			padding: 0px 10px;
			border-radius: 4px;
			line-height: 2.5;
		}
	}

	&.invalid {
		.input {
			color: #ab1515;
			border-color: #ab1515;
		}
	}

	&.invalid:before {
		content: attr(data-text);
		position: absolute;

		transform: translateY(50%);

		left: 100%;
		margin-left: 15px;

		width: 200px;
		padding: 10px;
		border-radius: 10px;
		color: #ab1515;
		text-align: left;

		display: none;
	}

	&.invalid:hover {
		position: relative;
	}

	&.invalid:hover:before {
		display: block;
	}

	&.updated {
		.input {
			border-color: #2a354f;
		}
	}
}

/** MODAL **/
.modal-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background-color: #fff;
	border-radius: 4px;

	.modal-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
			-ms-flex-pack: justify;
				justify-content: space-between;
		padding: 20px;
		border-bottom: 1px solid #eee;

		.modal-title {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
				-ms-flex-align: center;
					align-items: center;

			.icon {
				margin-right: 10px;
				padding: 5px 8px;
				border-radius: 4px;
				fill: #fff;
				background-color: #2a354f;
			}
		}

		.modal-actions {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}

	}
	
	.modal-body {
		padding: 20px;
	}

	.modal-footer {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
			-ms-flex-align: center;
				align-items: center;
		-webkit-box-pack: end;
			-ms-flex-pack: end;
				justify-content: flex-end;
		padding: 20px;

		.cancel-btn {
			margin-right: 20px;
			cursor: pointer;
			outline: none;
			border: none;
			background-color: #fff;
			color: #888;
			font-size: 16px;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/** INITIAL CIRCLE **/
.initial-circle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 10px;

	.initials {
		color: #fff;
		font-size: 16px;
		background-color: transparent;
	}

	.icon-wrapper {
		height: 20px;

		img {
			height: 20px;
		}
	}

	.avatar-img {
		width: 40px;
		height: 40px;
		border-radius: 10px;
	}
}

/** PAPER **/
.paper {
	background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4px;
}

/** SCROLL SHADOW **/
.scroll-shadow {
	outline: none;
	background:
		-webkit-linear-gradient(white 30%, hsla(0,0%,100%, 0)),
		-webkit-linear-gradient(hsla(0,0%,100%,0) 10px, white 70%) bottom,
		-webkit-radial-gradient(at top, rgba(0,0,0,0.2), transparent 70%), 
		-webkit-radial-gradient(at bottom, rgba(0,0,0,0.2), transparent 70%) bottom;
	background:
		-o-linear-gradient(white 30%, hsla(0,0%,100%, 0)),
		-o-linear-gradient(hsla(0,0%,100%,0) 10px, white 70%) bottom,
		-o-radial-gradient(at top, rgba(0,0,0,0.2), transparent 70%), 
		-o-radial-gradient(at bottom, rgba(0,0,0,0.2), transparent 70%) bottom;
	background:
		linear-gradient(white 30%, hsla(0,0%,100%, 0)),
		linear-gradient(hsla(0,0%,100%,0) 10px, white 70%) bottom,
		radial-gradient(at top, rgba(0,0,0,0.2), transparent 70%), 
		radial-gradient(at bottom, rgba(0,0,0,0.2), transparent 70%) bottom;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
	background-attachment: local, local, scroll, scroll;
}

/** TABLE SEARCH **/
.table-search {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 15px;
	background-color: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
				0 3px 6px 0 rgba(0,0,0,0.08);
	        box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
				0 3px 6px 0 rgba(0,0,0,0.08);
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;

	.icon {
		margin-bottom: 4px;
		fill: #888;
		font-size: 18px;

		&.cancel-icon {
			cursor: pointer;
		}
	}

	.input-wrapper {
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
		margin: 0 10px;

		.input {
			border-width: 1px;
		}
	}
}

.table-search-wrapper {
    display: flex;
	margin-bottom: 20px;
}

.table-email {
	color: #2a354f;
	font-size: 12px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    word-break: unset;
	white-space: nowrap;
	
	&:hover {
		color: #0B5FFF;
		text-decoration: underline;
	}
}

.floating-spinner-wrapper {
	position: absolute;
	z-index: 5;
	width: 100%;
}

.floating-spinner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;

	.spinner-wrapper {
		padding: 10px;
	}
}