@import '../core/styles/globals.scss';

/** Nav Container **/
.navbar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 60px;
	        flex: 0 0 60px;
	background-color: #2a354f;

	& > .nav-header > .logo-wrapper {
		.logo-lg {
			display: none;
		}

		.logo-sm {
			display: block;
		}
	}

	&.expand {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 190px;
		        flex: 0 0 190px;

		& > .nav-header > .logo-wrapper {
			.logo-lg {
				display: block;
			}

			.logo-sm {
				display: none;
			}
		}
	}
}

.nav-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	height: 60px;
	background-color: darken(#2a354f, 5%);

	.logo-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;

		.logo-lg {
			width: 128px;
			height: 26px;
			margin-right: 8px;
		}

		.logo-sm {
			height: 28px;
		}
	}
	
	.icon-wrapper {
		margin-right: 8px;
		width: 50px;
	}

	.nav-icon {
		height: 50px;
		border-radius: 50px;
		background-color: #ccc;
	}

	.nav-info {
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
		margin-right: 8px;

		.info-title {
			color: #fff;
			margin: 0;
			max-width: 170px;
			overflow: hidden;
			-o-text-overflow: ellipsis;
			   text-overflow: ellipsis;
			max-height: 38px;
		}

		.info-subtitle {
			color: #b2b2b2;
			max-width: 170px;
			overflow: hidden;
			-o-text-overflow: ellipsis;
			   text-overflow: ellipsis;
			max-height: 30px;
			line-height: 15px;
		}
	}

	.nav-btn {
		cursor: pointer;
	}
}

.nav-body {
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
}

.nav-footer {
	margin: 0;
	padding: 0;
}

.nav-body, .nav-footer {
	list-style: none;

	li {
		border-bottom: 1px solid rgba(256,256,256,0.1);

		.nav-link {
			color: #8DABC4;
			text-decoration: none;

			&.selected {
				color: #fff;

				.link-wrapper {
					background-color: darken(#2a354f, 5%);

					& > svg {
						fill: #fff;
					}
				}
			}

			.link-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 60px;

				&:hover {
					background-color: darken(#2a354f, 5%);
					color: #fff;
				}

				.link-title {
					font-size: 18px;
				}

				.icon {
					font-size: 20px;
				}

				& > svg {
					font-size: 24px;
					height: 29px;
					fill: #8DABC4;
				}
			}
		}

		.btn-wrapper {
			margin-bottom: 15px;
			padding: 0 20px;
		}
	}
}

/** Popover **/
.popover {
	.popover-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.popover-menu {
		min-width: 250px;
		position: absolute;
		z-index: 100;
		background-color: #fff;
		top: 75px;
		left: 15px;
		-webkit-box-shadow: 0 5px 10px rgba(0,0,0,.12);
		        box-shadow: 0 5px 10px rgba(0,0,0,.12);
		border: 1px solid rgba(0,0,0,.15);
		border-radius: 6px;

		.menu-body {
			.menu-container {
				border-bottom: 1px solid rgba(0,0,0,.15);
				padding: 15px 20px 0;

				.menu-header {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					    -ms-flex-align: center;
					        align-items: center;

					.header-info {
						padding: 5px 10px;

						.header-subtitle {
							padding-top: 5px;
							color: rgba(0, 0, 0, 0.5);
						}
					}
				}

				.menu-body {
					ul.menu-list {
						list-style: none;
						padding: 0;

						li {
							border-radius: 3px;
							padding: 0 10px;
							cursor: pointer;

							&:hover {
								background-color: #2D9EE0;

								span {
									color: #fff;
								}
							}

							span {
								color: #444;
								line-height: 1.5;
							}
						}
					}
				}
			}
		}

		.menu-footer {
			text-align: center;
			padding: 20px 0;
			background-color: #eee;
			border-radius: 0 0 5px 5px;

			span {
				color: #444;
				padding: 5px 10px;
				border-radius: 3px;
				cursor: pointer;

				&:hover {
					background-color: #2D9EE0;
					color: #fff;
				}
			}
		}
	}
}

#setup-popover {
	.popover-menu {
		width: 220px;
		top: 125px;
		left: 10px;

		.menu-container {
			padding: 15px 10px 0;

			.header-info {
				padding: 0;

				.header-title {
					font-size: 16px;
					color: #2a354f;
					font-weight: 300;
				}
			}

			.menu-body {
				.menu-list {
					margin: 15px 0 0;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					    -ms-flex-direction: column;
					        flex-direction: column;

					&:last-child {
						margin-bottom: 15px;
					}

					li {
						padding: 0;
						margin-bottom: 12px;

						a {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-orient: horizontal;
							-webkit-box-direction: normal;
							    -ms-flex-direction: row;
							        flex-direction: row;
							text-decoration: none;

							span {
								-o-text-overflow: ellipsis;
								   text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								width: 100%;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-align: center;
								    -ms-flex-align: center;
								        align-items: center;

								.placeholder {
									margin-bottom: 0;
								}
							}

							.icon-wrapper {
								margin-right: 5px;
								-ms-flex-preferred-size: 10%;
								    flex-basis: 10%;

								img {
									display: block;
									margin: 0 auto;
								}

								.www-icon {
									border-radius: 50%;
									margin-top: 3px;
								}

								.messenger-icon {
									margin-top: 2px;
								}

								.api-icon,
								.twilio-icon {
									margin-top: 3px;
								}
							}

							span:not(:hover) {
								color: #A3AAB1;
							}
						}
					}
				}
			}
		}
	}
}