/** Variables **/
$lightGreen: #4ECE3D;
$green: #1ABC9C;
$darkGreen: #4caf50;
$lightRed: #FFCCBC;
$red: #FF8A65;
$darkRed: #f44336;
$lightBlue: #DDEEFF;
$blue: #90CAF9;
$lightGrey: #E0E0E0;
$grey: #BDBDBD;

/** Media Queries **/
$mobile-break: 730px;

$boxShadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
