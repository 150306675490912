@import './globals.scss';

/*** CORE STYLES ***/
html, body {
	height: 100%;
}

body {
	margin: 0;
	color: #444;
	background-color: #ECF5FD;
	height: 100%;
	min-width: 1120px;
	font-weight: 400;
	font-family: 'Heebo', sans-serif;
	-webkit-font-smoothing: antialiased; /** Smooth chrome font **/
	-moz-osx-font-smoothing: grayscale; /** smooth ff font **/
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004); /** everything else **/

	@media only screen and (max-width: $mobile-break) {
		min-width: initial;
	}

	#fb-root {
		height: 0;
	}
}

section, nav, p, ul, li {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

h1, h2, h3, h4 {
	margin: 0;
	font-weight: normal;
}

h1 {
	font-size: 23px;
}

h2 {
	font-size: 20px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 18px;
}

p {
	font-size: 14px;
}

a {
	text-decoration: none;
}

input {
	outline: none;
	font-family: 'Heebo', sans-serif;
}